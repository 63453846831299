



















export default {
  name: 'SortGroupListHeader',
}
