














































import Vue, { PropType } from 'vue'
import { SortCondition, SortGroup } from '@/store/sort/sortGroupAdd'

export default Vue.extend({
  props: {
    sortGroup: {
      type: Object as PropType<SortGroup>,
      required: true,
    },
  },
  methods: {
    generateAdditionalInfo(sortCondition: SortCondition) {
      switch (sortCondition.type) {
        case 'field':
          return `field: (${sortCondition.fieldType}) ${sortCondition.field}`
        default:
          return ''
      }
    },
  },
})
