




































import Vue, { PropType } from 'vue'

import {
  ArrowDownIcon, ArrowUpIcon, Trash2Icon,
} from 'vue-feather-icons'
import SortGroupsStore from '@/store/sort/sortGroups'
import SearchInstanceStore from '@/store/search/searchInstance'
import { SortGroup } from '@/store/sort/sortGroupAdd'
import SortGroupElementConditionsList from '@/views/components/sort/SortGroupElementConditionsList.vue'

export default Vue.extend({
  components: {
    SortGroupElementConditionsList,
    ArrowUpIcon,
    ArrowDownIcon,
    Trash2Icon,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    sortGroup: {
      type: Object as PropType<SortGroup>,
      required: true,
    },
    confirmationDialog: {
      type: Function,
      required: true,
    },
  },
  methods: {
    moveUp(from: number) {
      SortGroupsStore.dispatch('moveUp', {
        fromPosition: from,
        searchId: SearchInstanceStore.state.searchInstanceId,
      })
    },
    moveDown(from: number) {
      SortGroupsStore.dispatch('moveDown', {
        fromPosition: from,
        searchId: SearchInstanceStore.state.searchInstanceId,
      })
    },
    confirmDelete(position: number) {
      const self = this

      this.confirmationDialog({
        type: 'confirm',
        color: 'danger',
        title: self.$t('search.immutablePhrase.deleteConfirmationHeader'),
        text: self.$t('search.immutablePhrase.deleteConfirmationText'),
        acceptText: self.$t('search.immutablePhrase.deleteConfirmationAcceptText'),
        cancelText: self.$t('search.immutablePhrase.deleteConfirmationCancelText'),
        accept() {
          SortGroupsStore.dispatch('deleteSortGroup',
            {
              searchId: SearchInstanceStore.state.searchInstanceId,
              position,
            })
        },
      })
    },
  },
})
