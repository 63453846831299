import Vue from 'vue'
import Vuex from 'vuex'
import { SearchApiRequest } from '@/store/search/searchApiRequestHelper'

Vue.use(Vuex)

interface SortCondition {
  type: string,
  field: string,
  order: string,
  additionalInfo: string,
}

interface SortGroup {
  id: string,
  name: string,
  order: string,
  description: string,
  conditions: SortCondition[]
}

interface SortGroupList {
  sortGroups: SortGroup[],
  searchId: string
}

const getInitialState = (): SortGroupList => ({
  sortGroups: [],
  searchId: '',
})

export default new Vuex.Store({
  state: getInitialState(),
  actions: {
    getSortGroups() {
      return this.state.sortGroups
    },
    getSortGroupsData(context: any, searchId: string) {
      SearchApiRequest.getSortsGroups(searchId).then((result: any) => {
        context.commit('setSortGroupsData', result.data)
      })
    },
    moveDown(context: any, moveData: { searchId: string, fromPosition: number }) {
      if (moveData.fromPosition === context.state.sortGroups.length - 1) {
        return
      }
      SearchApiRequest.moveSortsGroupDown(moveData.searchId, moveData.fromPosition).then((result: any) => {
        context.commit('setSortGroupsData', result.data)
      })
    },
    moveUp(context: any, moveData: { searchId: string, fromPosition: number }) {
      if (moveData.fromPosition <= 0) {
        return
      }
      SearchApiRequest.moveSortsGroupUp(moveData.searchId, moveData.fromPosition).then((result: any) => {
        context.commit('setSortGroupsData', result.data)
      })
    },
    deleteSortGroup(context: any, toDelete: {searchId: string, position: number}) {
      console.log(context.state.sortGroups[toDelete.position].id)
      SearchApiRequest.deleteSortGroup(toDelete.searchId, context.state.sortGroups[toDelete.position].id)
        .then((result: any) => {
          context.commit('setSortGroupsData', result.data)
        })
    },
  },
  mutations: {
    setSortGroupsData(state: any, data: any) {
      state.sortGroups = data
    },
    initDefaultData(state: any) {
      state.sortGroups = []
    },
  },
})
