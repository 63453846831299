






















import Vue from 'vue'
import SortGroupListHeader from '@/views/components/sort/SortGroupListHeader.vue'
import SortGroupListElement from '@/views/components/sort/SortGroupListElement.vue'
import SortGroupsStore from '@/store/sort/sortGroups'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  components: {
    SortGroupListElement,
    SortGroupListHeader,

  },
  computed: {
    sortGroups() {
      return SortGroupsStore.state.sortGroups
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    getData() {
      return SortGroupsStore.dispatch('getSortGroupsData', this.getSearchId)
    },
    addSortGroup() {
      SortGroupsStore.commit('initDefaultData')
      this.$router.push({ name: 'addSortGroupInstance', params: { searchId: this.getSearchId } })
    },
  },

})
