<template>
  <b-card :title="$t('sortSettings.header')">
    <sort-group-list />
  </b-card>
</template>

<script>

import SortStore from '@/store/sort/sort'
import SearchInstanceStore from '@/store/search/searchInstance'
import SortGroupList from '@/views/components/sort/SortGroupList.vue'

export default {
  components: { SortGroupList },
  computed: {
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    getData() {
      return SortStore.dispatch('getData', this.getSearchId)
    },
  },
}
</script>
